var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SpaceDataList", {
    attrs: {
      spaceData: _vm.educationSpaces,
      spaceType: _vm.spaceTypes.EDUCATION_SPACE,
      spaceIcon: "school",
      headerTitle: "Courses",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }